define("discourse/plugins/discourse-ai/discourse/components/suggestion-menus/ai-category-suggester", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse/helpers/category-badge", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-ai/discourse/lib/ai-helper-suggestions", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _service, _dButton, _dropdownMenu, _categoryBadge, _ajax, _ajaxError, _discourseI18n, _dMenu, _aiHelperSuggestions, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiCategorySuggester extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "suggestions", [_tracking.tracked], function () {
      return null;
    }))();
    #suggestions = (() => (dt7948.i(this, "suggestions"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "untriggers", [_tracking.tracked], function () {
      return [];
    }))();
    #untriggers = (() => (dt7948.i(this, "untriggers"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "triggerIcon", [_tracking.tracked], function () {
      return "discourse-sparkles";
    }))();
    #triggerIcon = (() => (dt7948.i(this, "triggerIcon"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "content", [_tracking.tracked], function () {
      return null;
    }))();
    #content = (() => (dt7948.i(this, "content"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "topicContent", [_tracking.tracked], function () {
      return null;
    }))();
    #topicContent = (() => (dt7948.i(this, "topicContent"), void 0))();
    constructor() {
      super(...arguments);
      if (!this.topicContent && this.args.composer?.reply === undefined) {
        this.fetchTopicContent();
      }
    }
    async fetchTopicContent() {
      await (0, _ajax.ajax)(`/t/${this.args.buffered.content.id}.json`).then(_ref => {
        let {
          post_stream
        } = _ref;
        this.topicContent = post_stream.posts[0].cooked;
      });
    }
    get showSuggestionButton() {
      const composerFields = document.querySelector(".composer-fields");
      this.content = this.args.composer?.reply || this.topicContent;
      const showTrigger = this.content?.length > _aiHelperSuggestions.MIN_CHARACTER_COUNT;
      if (composerFields) {
        if (showTrigger) {
          composerFields.classList.add("showing-ai-suggestions");
        } else {
          composerFields.classList.remove("showing-ai-suggestions");
        }
      }
      return this.siteSettings.ai_embeddings_enabled && showTrigger;
    }
    async loadSuggestions() {
      if (this.suggestions && !this.dMenu.expanded) {
        return this.suggestions;
      }
      this.loading = true;
      this.triggerIcon = "spinner";
      try {
        const {
          assistant
        } = await (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest_category", {
          method: "POST",
          data: {
            text: this.content
          }
        });
        this.suggestions = assistant;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
        this.triggerIcon = "rotate";
      }
      return this.suggestions;
    }
    static #_8 = (() => dt7948.n(this.prototype, "loadSuggestions", [_object.action]))();
    applySuggestion(suggestion) {
      const composer = this.args.composer;
      const buffered = this.args.buffered;
      if (composer) {
        composer.set("categoryId", suggestion.id);
        composer.get("categoryId");
      }
      if (buffered) {
        this.args.buffered.set("category_id", suggestion.id);
      }
      return this.dMenu.close();
    }
    static #_9 = (() => dt7948.n(this.prototype, "applySuggestion", [_object.action]))();
    onRegisterApi(api) {
      this.dMenu = api;
    }
    static #_10 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    onClose() {
      this.triggerIcon = "discourse-sparkles";
    }
    static #_11 = (() => dt7948.n(this.prototype, "onClose", [_object.action]))();
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showSuggestionButton}}
          <DMenu
            @title={{i18n "discourse_ai.ai_helper.suggest"}}
            @icon={{this.triggerIcon}}
            @identifier="ai-category-suggester"
            @onClose={{this.onClose}}
            @triggerClass="btn-transparent suggestion-button suggest-category-button {{if
              this.loading
              'is-loading'
            }}"
            @contentClass="ai-suggestions-menu"
            @onRegisterApi={{this.onRegisterApi}}
            @modalForMobile={{true}}
            @untriggers={{this.untriggers}}
            {{on "click" this.loadSuggestions}}
          >
            <:content>
              {{#unless this.loading}}
                <DropdownMenu as |dropdown|>
                  {{#each this.suggestions as |suggestion index|}}
                    <dropdown.item>
                      <DButton
                        class="category-row"
                        data-name={{suggestion.name}}
                        data-value={{index}}
                        title={{suggestion.name}}
                        @action={{fn this.applySuggestion suggestion}}
                      >
                        <div class="category-status">
                          {{categoryBadge suggestion}}
                          <span class="topic-count">x
                            {{suggestion.topicCount}}</span>
                        </div>
                      </DButton>
                    </dropdown.item>
                  {{/each}}
                </DropdownMenu>
              {{/unless}}
            </:content>
          </DMenu>
        {{/if}}
      
    */
    {
      "id": "ckFiQc91",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showSuggestionButton\"]],[[[1,\"      \"],[8,[32,0],[[4,[32,2],[\"click\",[30,0,[\"loadSuggestions\"]]],null]],[[\"@title\",\"@icon\",\"@identifier\",\"@onClose\",\"@triggerClass\",\"@contentClass\",\"@onRegisterApi\",\"@modalForMobile\",\"@untriggers\"],[[28,[32,1],[\"discourse_ai.ai_helper.suggest\"],null],[30,0,[\"triggerIcon\"]],\"ai-category-suggester\",[30,0,[\"onClose\"]],[29,[\"btn-transparent suggestion-button suggest-category-button \",[52,[30,0,[\"loading\"]],\"is-loading\"]]],\"ai-suggestions-menu\",[30,0,[\"onRegisterApi\"]],true,[30,0,[\"untriggers\"]]]],[[\"content\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"loading\"]]],[[[1,\"            \"],[8,[32,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,0,[\"suggestions\"]]],null]],null],null,[[[1,\"                \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n                  \"],[8,[32,4],[[24,0,\"category-row\"],[16,\"data-name\",[30,2,[\"name\"]]],[16,\"data-value\",[30,3]],[16,\"title\",[30,2,[\"name\"]]]],[[\"@action\"],[[28,[32,5],[[30,0,[\"applySuggestion\"]],[30,2]],null]]],[[\"default\"],[[[[1,\"\\n                    \"],[10,0],[14,0,\"category-status\"],[12],[1,\"\\n                      \"],[1,[28,[32,6],[[30,2]],null]],[1,\"\\n                      \"],[10,1],[14,0,\"topic-count\"],[12],[1,\"x\\n                        \"],[1,[30,2,[\"topicCount\"]]],[13],[1,\"\\n                    \"],[13],[1,\"\\n                  \"]],[]]]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n\"]],[2,3]],null],[1,\"            \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"dropdown\",\"suggestion\",\"index\"],false,[\"if\",\"unless\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/suggestion-menus/ai-category-suggester.js",
      "scope": () => [_dMenu.default, _discourseI18n.i18n, _modifier.on, _dropdownMenu.default, _dButton.default, _helper.fn, _categoryBadge.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiCategorySuggester;
});