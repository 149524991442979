define("discourse/plugins/discourse-ai/discourse/admin/models/ai-tool", ["exports", "@ember-compat/tracked-built-ins", "discourse/models/rest"], function (_exports, _trackedBuiltIns, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["id", "name", "tool_name", "description", "parameters", "script", "summary", "rag_uploads", "rag_chunk_tokens", "rag_chunk_overlap_tokens", "enabled"];
  class AiTool extends _rest.default {
    createProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    updateProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    trackParameters(parameters) {
      return new _trackedBuiltIns.TrackedArray(parameters?.map(p => {
        const parameter = new _trackedBuiltIns.TrackedObject(p);
        if (parameter.enum && parameter.enum.length) {
          parameter.enum = new _trackedBuiltIns.TrackedArray(parameter.enum);
        } else {
          parameter.enum = null;
        }
        return parameter;
      }));
    }
    workingCopy() {
      const attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.parameters = this.trackParameters(attrs.parameters);
      return this.store.createRecord("ai-tool", attrs);
    }
  }
  _exports.default = AiTool;
});